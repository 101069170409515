import React, { useState, useMemo } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Chip,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import { toast } from 'react-toastify'
import { IDispatchStatus } from '../../types/dispatchStatus'
import {
  useUpdateDispatchStatusActiveStatusMutation,
  useUpdateDispatchStatusMutation,
} from '../../redux/api/dispatchStatusApi'
import { useAppSelector } from '../../redux/store'

const styles = {
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  actionButton: {
    backgroundColor: '#1976d2',
    color: 'white',
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#115293',
    },
  },
  modalButton: {
    textTransform: 'none',
    textDecoration: 'none',
    backgroundColor: '#f1f1f1',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
}

// Helper functions for sorting
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof IDispatchStatus>(order: Order, orderBy: Key) {
  return order === 'desc'
    ? (a: IDispatchStatus, b: IDispatchStatus) => descendingComparator(a, b, orderBy)
    : (a: IDispatchStatus, b: IDispatchStatus) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

// Head cells definition
interface HeadCell {
  id: keyof IDispatchStatus
  label: string
}

const headCells: readonly HeadCell[] = [
  { id: 'flightTrackerStatusDispatchId', label: 'ID' },
  { id: 'isActiveFlg', label: 'Active Status' },
  { id: 'flightTrackerStatusDispatch', label: 'Dispatch' },
  { id: 'flightTrackerStatusConsumer', label: 'Consumer' },
  { id: 'flightTrackerStatusDealer', label: 'Dealer' },
  { id: 'flightTrackerStatusServicer', label: 'Servicer' },
  { id: 'createdOn', label: 'Created' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'updatedOn', label: 'Updated' },
  { id: 'updatedBy', label: 'Updated By' },
]

interface DispatchStatusTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IDispatchStatus) => void
  order: Order
  orderBy: string
  numSelected: number
  rowCount: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function DispatchStatusTableHead(props: DispatchStatusTableHeadProps) {
  const { onRequestSort, order, orderBy, numSelected, rowCount, onSelectAllClick } = props
  const createSortHandler =
    (property: keyof IDispatchStatus) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#1976d2' }}>
        <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all dispatch statuses' }}
          />
        </TableCell>
        <TableCell align='left' sx={{ color: 'white' }}></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: 'white' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface DispatchStatusTableProps {
  isLoading: boolean
  rows: IDispatchStatus[]
  searchKey: string
  editStatus: (status: IDispatchStatus) => void
  refetch: () => void
}

export const DispatchStatusTable = ({
  isLoading,
  rows,
  searchKey,
  editStatus,
  refetch,
}: DispatchStatusTableProps) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof IDispatchStatus>('flightTrackerStatusDispatch')
  const [selected, setSelected] = useState<number[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogLoaderState, setDialogLoaderState] = useState(false)
  const [dialogAction, setDialogAction] = useState<'activate' | 'deactivate' | 'delete' | null>(
    null,
  )

  const [
    updateDispatchStatusActiveStatus,
    { isSuccess, isError, isLoading: statusUpdateLoading, error },
  ] = useUpdateDispatchStatusActiveStatusMutation()

  const user = useAppSelector((state) => state.userState.user)

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IDispatchStatus) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const filteredRowsWithSearch = useMemo(
    () =>
      rows.filter(
        (row) =>
          row.flightTrackerStatusDispatch.toLowerCase().includes(searchKey.toLowerCase()) ||
          row.flightTrackerStatusDealer.toLowerCase().includes(searchKey.toLowerCase()) ||
          row.flightTrackerStatusConsumer.toLowerCase().includes(searchKey.toLowerCase()) ||
          row.flightTrackerStatusServicer.toLowerCase().includes(searchKey.toLowerCase()) ||
          row.createdBy.toLowerCase().includes(searchKey.toLowerCase()),
      ),
    [rows, searchKey],
  )

  const sortedRowsWithPagination = useMemo(
    () =>
      stableSort(filteredRowsWithSearch, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [filteredRowsWithSearch, order, orderBy, page, rowsPerPage],
  )

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedIds = sortedRowsWithPagination.map((n) => n.flightTrackerStatusDispatchId)
      setSelected(newSelectedIds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenDialog = (action: 'activate' | 'deactivate' | 'delete') => {
    setDialogAction(action)
    setShowDialog(true)
  }

  const handleCloseDialog = () => {
    setDialogAction(null)
    setShowDialog(false)
    setDialogLoaderState(false)
    setSelected([])
  }

  const handleDialogAction = async () => {
    setDialogLoaderState(true)
    if (dialogAction === 'delete') {
      await updateDispatchStatusActiveStatus({
        flightTrackerStatusDispatchIds: selected,
        isActiveFlg: 0,
        updatedBy: `${user?.firstName} ${user?.lastName}`,
      })
        .then(async () => {
          toast.success('Selected statuses deleted successfully')
          await refetch()
        })
        .catch((error) => {
          toast.error('Failed to delete selected statuses')
          console.error(error)
        })
    } else {
      const isActiveFlgNew = dialogAction === 'activate' ? 1 : 0
      await updateDispatchStatusActiveStatus({
        flightTrackerStatusDispatchIds: selected,
        isActiveFlg: isActiveFlgNew,
        updatedBy: `${user?.firstName} ${user?.lastName}`,
      })
        .then(async () => {
          toast.success(
            `Selected statuses ${
              dialogAction === 'activate' ? 'activated' : 'deactivated'
            } successfully`,
          )
          await refetch()
        })
        .catch((error) => {
          toast.error(
            `Failed to ${
              dialogAction === 'activate' ? 'activate' : 'deactivate'
            } selected statuses`,
          )
          console.error(error)
        })
    }
    handleCloseDialog()
  }

  return (
    <>
      <Box sx={styles.actionsContainer}>
        <Button
          variant='contained'
          onClick={() => handleOpenDialog('activate')}
          sx={styles.actionButton}
          disabled={selected.length === 0}
        >
          Activate
        </Button>
        <Button
          variant='contained'
          onClick={() => handleOpenDialog('deactivate')}
          sx={styles.actionButton}
          disabled={selected.length === 0}
        >
          Deactivate
        </Button>
        <Button
          variant='contained'
          onClick={() => handleOpenDialog('delete')}
          sx={styles.actionButton}
          disabled={selected.length === 0}
        >
          Delete
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <DispatchStatusTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            numSelected={selected.length}
            rowCount={rows.length}
            onSelectAllClick={handleSelectAllClick}
          />
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : filteredRowsWithSearch.length ? (
              sortedRowsWithPagination.map((row) => {
                const isItemSelected = selected.indexOf(row.flightTrackerStatusDispatchId) !== -1
                return (
                  <TableRow
                    hover
                    onClick={(event: any) => handleClick(event, row.flightTrackerStatusDispatchId)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.flightTrackerStatusDispatchId}
                    selected={isItemSelected}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox color='primary' checked={isItemSelected} />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          editStatus(row)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.flightTrackerStatusDispatchId}</TableCell>
                    <TableCell>
                      <Chip
                        label={row.isActiveFlg ? 'Active' : 'Inactive'}
                        color={row.isActiveFlg ? 'success' : 'default'}
                      />
                    </TableCell>
                    <TableCell>{row.flightTrackerStatusDispatch}</TableCell>
                    <TableCell>{row.flightTrackerStatusConsumer}</TableCell>
                    <TableCell>{row.flightTrackerStatusDealer}</TableCell>
                    <TableCell>{row.flightTrackerStatusServicer}</TableCell>
                    <TableCell>{moment(row.createdOn).format('L')}</TableCell>
                    <TableCell>{row.createdBy}</TableCell>
                    <TableCell>{row?.updatedOn ? moment(row.updatedOn).format('L') : ''}</TableCell>
                    <TableCell>{row.updatedBy}</TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableCell colSpan={headCells.length + 2}>No Data to display</TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={filteredRowsWithSearch.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={showDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogAction} the selected statuses?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={styles.modalButton}>
            Cancel
          </Button>
          <Button onClick={handleDialogAction} sx={styles.modalButton}>
            Confirm {dialogLoaderState && <CircularProgress size={18} sx={{ marginLeft: '2px' }} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
