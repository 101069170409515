import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFlightTrackerServicer, ActiveFlg } from '../../types'

interface IFlightTrackerServicersState {
  servicers: IFlightTrackerServicer[] | null
}

const initialState: IFlightTrackerServicersState = {
  servicers: null,
}

export const flightTrackerServicerSlice = createSlice({
  initialState,
  name: 'flightTrackerServicerSlice',
  reducers: {
    setFlightTrackerServicers: (state, action: PayloadAction<IFlightTrackerServicer[]>) => {
      state.servicers = action.payload
    },
    updateFlightTrackerServicer: (state, action: PayloadAction<IFlightTrackerServicer>) => {
      if (state.servicers) {
        const index = state.servicers.findIndex(
          (servicer) =>
            servicer.flightTrackerStatusServicerId === action.payload.flightTrackerStatusServicerId,
        )
        if (index !== -1) {
          state.servicers[index] = action.payload
        }
      }
    },
    deleteFlightTrackerServicers: (state, action: PayloadAction<number[]>) => {
      if (state.servicers) {
        state.servicers = state.servicers.filter(
          (servicer) => !action.payload.includes(servicer.flightTrackerStatusServicerId),
        )
      }
    },
    updateFlightTrackerServicerStatus: (
      state,
      action: PayloadAction<{ servicerIds: number[]; isActiveFlg: ActiveFlg }>,
    ) => {
      if (state.servicers) {
        state.servicers = state.servicers.map((servicer) =>
          action.payload.servicerIds.includes(servicer.flightTrackerStatusServicerId)
            ? { ...servicer, isActiveFlg: action.payload.isActiveFlg }
            : servicer,
        )
      }
    },
  },
})

export default flightTrackerServicerSlice.reducer

export const {
  setFlightTrackerServicers,
  updateFlightTrackerServicer,
  deleteFlightTrackerServicers,
  updateFlightTrackerServicerStatus,
} = flightTrackerServicerSlice.actions
