/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  Modal,
  styled,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { useCreateLeadTypeMutation, useUpdateLeadTypeMutation } from '../../redux/api/leadTypeApi'
import { IElxData, RoleOption } from '../../types'
import Select, { StylesConfig } from 'react-select'
import makeAnimated from 'react-select/animated'
import { useCreateModuleMutation, useUpdateModuleDetailsMutation } from '../../redux/api/moduleApi'

const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}))
interface Option {
  value: string | number
  label: string
}

interface Props {
  leadType?: IElxData
  onClose: () => void
  refetch: () => void
  open: boolean
  options?: Option[]
  applications?: Option[]
}
const DEFAULT_ROLE_ITEM: RoleOption = {
  applicationId: -1,
  userRoleId: -1,
  isDefaultFlg: false,
}

const validationSchema = yup.object({
  data: yup.string().required('LeadType is required'),
})

const customStyles: StylesConfig<any, false> = {
  menu: (provided) => ({
    ...provided,
    position: 'absolute',
    zIndex: 9999,
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    height: '56px',
  }),
}

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    height: '95vh',
    overflow: 'auto',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 4,
  },
  noMargin: {
    margin: '0px!important',
  },
  alignItemsCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
  fontStyleForm: {
    fontSize: '1rem',
  },
  dealerGroupContainer: {
    display: 'flex',
    marginTop: '.5rem',
  },
}

const animatedComponents = makeAnimated()

export const ModuleTypeForm = ({
  onClose,
  refetch,
  open,
  leadType,
  applications = [],
  options = [],
}: Props) => {
  const [createModuleType, { isLoading, isError, error, isSuccess }] = useCreateModuleMutation()
  const [
    updateModuleType,
    { isLoading: uIsLoading, isError: uIsError, error: uError, isSuccess: uIsSuccess },
  ] = useUpdateModuleDetailsMutation()

  const [roles, setRoles] = React.useState<RoleOption[]>([DEFAULT_ROLE_ITEM])

  const getApplicationOptions = (roleApplicationId: any) => {
    const prevApplications = roles
      .filter((role) => role.applicationId !== -1 && role.applicationId !== roleApplicationId)
      .map((role) => role.applicationId)

    return applications.filter(
      (application) => !prevApplications.includes(Number(application.value)),
    )
  }

  const formik = useFormik({
    initialValues: {
      data: leadType?.moduleName ?? '',
      description: leadType?.moduleDescription ?? '',
    },
    validationSchema,
    onSubmit: (values) => {
      const selectedApplicationIds = roles
        .map((role) => role.applicationId)
        .filter((id) => id !== -1) // Exclude default placeholder IDs
        .join(',')

      const isUpdating = !!leadType?.moduleId // Check if it's an update

      const requestData = {
        moduleType: values.data,
        moduleDescription: values.description,
        ...(isUpdating
          ? { newApplications: selectedApplicationIds } // Use `newApplications` for updates
          : { applications: selectedApplicationIds }), // Use `applications` for creates
      }

      if (isUpdating) {
        updateModuleType({
          moduleId: leadType.moduleId,
          ...requestData,
        })
      } else {
        createModuleType({
          ...requestData,
        })
      }
    },
  })

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [isLoading])

  React.useEffect(() => {
    if (uIsSuccess) {
      toast.success('Success!')
      formik.resetForm()
      onClose()
      refetch()
    }
    if (uIsError) {
      if (Array.isArray((uError as any)?.data?.error)) {
        const errors = uError as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((uError as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [uIsLoading])

  React.useEffect(() => {
    formik.setValues({
      data: leadType?.moduleName ?? '',
      description: leadType?.moduleDescription ?? '',
    })
  }, [leadType])

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  const addOption = () => {
    if (roles.length >= applications.length) {
      return
    }

    setRoles([...roles, DEFAULT_ROLE_ITEM])
  }
  const removeRole = (idx: number) => {
    if (roles.length === 1) {
      return
    }

    setRoles([...roles.slice(0, idx), ...roles.slice(idx + 1)])
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            Module type details
          </Typography>
        </Box>
        <Box sx={{ p: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <Item>
                <TextField
                  fullWidth
                  id='data'
                  name='data'
                  label='Module Type'
                  autoComplete='off'
                  value={formik.values.data}
                  onChange={formik.handleChange}
                  error={formik.touched.data && Boolean(formik.errors.data)}
                  helperText={formik.touched.data && formik.errors.data}
                />
              </Item>
            </Stack>
            <Stack spacing={2}>
              <Item>
                <TextField
                  fullWidth
                  id='description'
                  name='description'
                  label='Description'
                  autoComplete='off'
                  multiline
                  rows={3}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                />
              </Item>
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h6' component='h5'>
                Applications
              </Typography>
              <IconButton onClick={addOption}>
                <AddCircleIcon color='primary' />
              </IconButton>
            </Box>
            <Typography
              sx={styles.noMargin}
              variant='subtitle1'
              component='span'
              align='center'
              color='lightgrey'
            >
              Add applications with roles
            </Typography>
            {roles.map((role, idx) => (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }} key={`role-${idx}`}>
                <Select
                  closeMenuOnSelect
                  components={animatedComponents}
                  placeholder='Application'
                  value={applications.find(
                    (application) => application.value === role.applicationId,
                  )}
                  onChange={(selectedOption: any) => {
                    if (selectedOption) {
                      setRoles([
                        ...roles.slice(0, idx),
                        {
                          applicationId: selectedOption.value,
                          userRoleId: role.userRoleId,
                          isDefaultFlg: role.isDefaultFlg,
                        },
                        ...roles.slice(idx + 1),
                      ])
                    }
                  }}
                  options={getApplicationOptions(role.applicationId)}
                  className='react-select-container'
                  styles={{
                    ...customStyles,
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: '56px',
                    }),
                  }}
                />

                <Tooltip title='Delete' arrow>
                  <IconButton onClick={() => removeRole(idx)}>
                    <CancelIcon color='error' />
                  </IconButton>
                </Tooltip>
              </Box>
            ))}

            <Box sx={{ textAlign: 'right', mt: '30px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px', textDecoration: 'none' }}
                color='error'
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                sx={{ borderRadius: '20px', textDecoration: 'none' }}
                size='large'
                type='submit'
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
