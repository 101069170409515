import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IFlightTrackerDealer, ActiveFlg } from '../../types'

interface IFlightTrackerDealersState {
  dealers: IFlightTrackerDealer[] | null
}

const initialState: IFlightTrackerDealersState = {
  dealers: null,
}

export const flightTrackerDealerSlice = createSlice({
  initialState,
  name: 'flightTrackerDealerSlice',
  reducers: {
    setFlightTrackerDealers: (state, action: PayloadAction<IFlightTrackerDealer[]>) => {
      state.dealers = action.payload
    },
    updateFlightTrackerDealer: (state, action: PayloadAction<IFlightTrackerDealer>) => {
      if (state.dealers) {
        const index = state.dealers.findIndex(
          (dealer) =>
            dealer.flightTrackerStatusDealerId === action.payload.flightTrackerStatusDealerId,
        )
        if (index !== -1) {
          state.dealers[index] = action.payload
        }
      }
    },
    deleteFlightTrackerDealers: (state, action: PayloadAction<number[]>) => {
      if (state.dealers) {
        state.dealers = state.dealers.filter(
          (dealer) => !action.payload.includes(dealer.flightTrackerStatusDealerId),
        )
      }
    },
    updateFlightTrackerDealerStatus: (
      state,
      action: PayloadAction<{ dealerIds: number[]; isActiveFlg: ActiveFlg }>,
    ) => {
      if (state.dealers) {
        state.dealers = state.dealers.map((dealer) =>
          action.payload.dealerIds.includes(dealer.flightTrackerStatusDealerId)
            ? { ...dealer, isActiveFlg: action.payload.isActiveFlg }
            : dealer,
        )
      }
    },
  },
})

export default flightTrackerDealerSlice.reducer

export const {
  setFlightTrackerDealers,
  updateFlightTrackerDealer,
  deleteFlightTrackerDealers,
  updateFlightTrackerDealerStatus,
} = flightTrackerDealerSlice.actions
