import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  CircularProgress,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material'
import { DealerPortalForm } from './DealerPortalForm'
import { DealerPortalTable } from '../../components/Table/DealerPortalTable'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { IFlightTrackerDealer } from '../../types'
import { useGetFlightTrackerDealersQuery } from '../../redux/api/flightTrackerDealerApi'

const styles = {
  container: {
    padding: '20px',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  searchInput: {
    width: '100%',
    marginRight: '10px',
  },
  addButton: {
    textTransform: 'none',
    textDecoration: 'none',
  },
}

export const DealerPortalPage = () => {
  const [searchKey, setSearchKey] = useState('')
  const [editingDealer, setEditingDealer] = useState<IFlightTrackerDealer | null>(null)
  const { data, isLoading, refetch } = useGetFlightTrackerDealersQuery(null)

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value)
  }

  const handleEditDealer = (dealer: IFlightTrackerDealer) => {
    setEditingDealer(dealer)
  }

  const handleCloseForm = () => {
    setEditingDealer(null)
    refetch()
  }

  const handleAddDealer = () => {
    setEditingDealer({} as IFlightTrackerDealer)
  }

  return (
    <Box sx={styles.container}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Flight Tracker Dealer Statuses
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={handleSearchChange}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px', textDecoration: 'none' }}
            startIcon={<AddIcon />}
            onClick={handleAddDealer}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      {isLoading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <DealerPortalTable
          isLoading={isLoading}
          rows={data || []}
          searchKey={searchKey}
          editDealer={handleEditDealer}
          refetch={refetch}
        />
      )}
      {editingDealer && (
        <DealerPortalForm
          data={editingDealer}
          onClose={handleCloseForm}
          refetch={refetch}
          open={!!editingDealer}
        />
      )}
    </Box>
  )
}
