import React, { useEffect } from 'react'
import { Button, TextField, Stack, Typography, Box, Modal, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { useAppSelector } from '../../redux/store'
import { IFlightTrackerDealer } from '../../types'
import {
  useCreateFlightTrackerDealerMutation,
  useUpdateFlightTrackerDealerMutation,
} from '../../redux/api/flightTrackerDealerApi'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingBottom: '0',
  },
  content: {
    maxHeight: '80vh',
    overflow: 'auto',
    padding: '15px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 0,
  },
}

interface Props {
  data: IFlightTrackerDealer | undefined | null
  onClose: () => void
  refetch: () => void
  open: boolean
}

const validationSchema = yup.object({
  flightTrackerStatusDealer: yup.string().required('Dealer name is required'),
})

export const DealerPortalForm = ({ onClose, refetch, open, data }: Props) => {
  const [createDealer, { isLoading: cIsLoading }] = useCreateFlightTrackerDealerMutation()
  const [updateDealer, { isLoading: uIsLoading }] = useUpdateFlightTrackerDealerMutation()
  const user = useAppSelector((state) => state.userState.user)

  const formik = useFormik({
    initialValues: {
      flightTrackerStatusDealer: data?.flightTrackerStatusDealer || '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (data?.flightTrackerStatusDealerId) {
        const requestData = {
          flightTrackerStatusDealer: values.flightTrackerStatusDealer,
          updatedBy: `${user?.firstName} ${user?.lastName}`,
          flightTrackerStatusDealerId: data.flightTrackerStatusDealerId,
        }
        updateDealer(requestData)
          .then(() => {
            toast.success('Dealer updated successfully')
            refetch()
            onClose()
          })
          .catch((error: any) => {
            toast.error('Failed to update dealer')
            console.error(error)
          })
      } else {
        const requestData = {
          flightTrackerStatusDealer: values.flightTrackerStatusDealer,
          Application: 'Intranet',
          createdBy: `${user?.firstName} ${user?.lastName}`,
        }
        createDealer(requestData)
          .then(() => {
            toast.success('Dealer created successfully')
            refetch()
            onClose()
          })
          .catch((error: any) => {
            toast.error('Failed to create dealer')
            console.error(error)
          })
      }
    },
  })

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
  }, [open])

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            {data?.flightTrackerStatusDealerId ? 'Edit Dealer' : 'Create Dealer'}
          </Typography>
        </Box>
        <Box sx={styles.content}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id='flightTrackerStatusDealer'
              name='flightTrackerStatusDealer'
              label='Dealer Name'
              autoComplete='off'
              value={formik.values.flightTrackerStatusDealer}
              onChange={formik.handleChange}
              error={
                formik.touched.flightTrackerStatusDealer &&
                Boolean(formik.errors.flightTrackerStatusDealer)
              }
              helperText={
                formik.touched.flightTrackerStatusDealer && formik.errors.flightTrackerStatusDealer
              }
            />
            <Box sx={{ textAlign: 'right', marginTop: '20px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                size='large'
                type='submit'
                disabled={cIsLoading || uIsLoading}
                sx={{ borderRadius: '20px' }}
              >
                Submit
                {(cIsLoading || uIsLoading) && <CircularProgress size={20} sx={{ ml: 1 }} />}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
