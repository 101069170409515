import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  ICreateFlightTrackerServicerRequest,
  IFlightTrackerServicer,
  IUpdateFlightTrackerServicerRequest,
  IUpdateFlightTrackerServicerStatusRequest,
} from '../../types/servicerPortal'
import { ActiveFlg, GenericResponse } from '../../types'
import customOpwFetchBase from './customOpwFetchBase'

export const flightTrackerServicerApi = createApi({
  reducerPath: 'flightTrackerServicerApi',
  baseQuery: customOpwFetchBase,
  endpoints: (builder) => ({
    getFlightTrackerServicers: builder.query<IFlightTrackerServicer[], ActiveFlg | null>({
      query(isActiveFlg) {
        return {
          url: `flighttrackerservicer/get`,
          params: {
            isActiveFlg,
          },
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { request: IFlightTrackerServicer[] } }) => {
        return result.data.request
      },
    }),
    createFlightTrackerServicer: builder.mutation<
      GenericResponse,
      ICreateFlightTrackerServicerRequest
    >({
      query(newServicer) {
        return {
          url: 'flighttrackerservicer/create',
          method: 'POST',
          body: newServicer,
          credentials: 'include',
        }
      },
    }),
    updateFlightTrackerServicer: builder.mutation<
      GenericResponse,
      IUpdateFlightTrackerServicerRequest
    >({
      query(updatedServicer) {
        return {
          url: `flighttrackerservicer/update`,
          method: 'PUT',
          body: updatedServicer,
          credentials: 'include',
        }
      },
    }),
    updateFlightTrackerServicerStatus: builder.mutation<
      GenericResponse,
      IUpdateFlightTrackerServicerStatusRequest
    >({
      query(data) {
        return {
          url: 'flighttrackerservicer/updatestatus',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
    deleteFlightTrackerServicerStatus: builder.mutation<
      GenericResponse,
      IUpdateFlightTrackerServicerStatusRequest
    >({
      query(data) {
        return {
          url: 'flighttrackerservicer/updatestatus',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetFlightTrackerServicersQuery,
  useCreateFlightTrackerServicerMutation,
  useUpdateFlightTrackerServicerMutation,
  useUpdateFlightTrackerServicerStatusMutation,
  useDeleteFlightTrackerServicerStatusMutation,
} = flightTrackerServicerApi
