import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex'
import { logout } from '../slice/userSlice'
import { reset } from '../slice/authSlice'

// Create a new mutex
const mutex = new Mutex()

const baseOpwUrl = `${process.env.REACT_APP_OPW_SERVER_ENDPOINT}/`

const customOpwFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const queryBase = fetchBaseQuery({
    baseUrl: baseOpwUrl, // Use a single base URL
    credentials: 'include', // Include credentials (cookies) in all requests
  })

  await mutex.waitForUnlock()

  let result = await queryBase(args, api, extraOptions)

  if ((result.error?.data as any)?.message === 'You are not logged in') {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refreshResult = await queryBase(
          { credentials: 'include', url: 'auth/refresh' },
          api,
          extraOptions,
        )

        if (refreshResult.data) {
          result = await queryBase(args, api, extraOptions) // Retry the initial query
        } else {
          api.dispatch(reset())
          api.dispatch(logout())
          window.location.href = '/login'
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock()
      result = await queryBase(args, api, extraOptions)
    }
  }

  return result
}

export default customOpwFetchBase
