import React from 'react'
import { Stack } from '@mui/system'
import { Button, Container, Typography, OutlinedInput, InputAdornment } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { FullScreenLoader, SurveyMonkeyMasterTable } from '../../components'
import { SurveyMonkeyMasterForm } from './SurveyMonkeyMasterForm'
import { toast } from 'react-toastify'
import { ISurveyMonkeyMaster, IUpdateSurveyMonkeyMasterStatusRequest } from '../../types'
import {
  useGetSurveyMonkeyMasterListQuery,
  useUpdateSurveyMonkeyMasterStatusMutation,
} from '../../redux/api/surveyMonkeyMasterApi'

export function SurveyMonkeyMasterPage() {
  const { isLoading, data, refetch } = useGetSurveyMonkeyMasterListQuery()
  const [updateStatus, { isSuccess, isError, isLoading: loading, error }] =
    useUpdateSurveyMonkeyMasterStatusMutation()
  const [searchKey, setSearchKey] = React.useState<string>('')
  const [isVisible, setIsVisible] = React.useState(false)
  const [editableSurveyMonkey, setEditableSurveyMonkey] = React.useState<ISurveyMonkeyMaster>()

  const handleStatusUpdate = (data: IUpdateSurveyMonkeyMasterStatusRequest) => {
    updateStatus(data)
  }

  const onEditSurveyMaster = (data: ISurveyMonkeyMaster) => {
    setEditableSurveyMonkey({ ...data })
    setIsVisible(true)
  }
  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [loading])

  if (isLoading || !data) {
    return <FullScreenLoader />
  }

  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Survey Monkey Master
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={(event) => setSearchKey(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px', textDecoration: 'none' }}
            startIcon={<AddIcon />}
            onClick={() => {
              setIsVisible(true)
              setEditableSurveyMonkey(undefined)
            }}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <SurveyMonkeyMasterTable
        rows={data || []}
        searchKey={searchKey}
        editSurveyMaster={onEditSurveyMaster}
        disableSurveyMaster={handleStatusUpdate}
      />
      <SurveyMonkeyMasterForm
        open={isVisible}
        onClose={() => setIsVisible(false)}
        surveyMonkeyMaster={editableSurveyMonkey}
        refetch={refetch}
      />
    </Container>
  )
}
