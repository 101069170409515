import React, { useEffect } from 'react'
import { Button, TextField, Stack, Typography, Box, Modal, CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { useAppSelector } from '../../redux/store'
import { IFlightTrackerServicer } from '../../types/servicerPortal'
import {
  useCreateFlightTrackerServicerMutation,
  useUpdateFlightTrackerServicerMutation,
} from '../../redux/api/flightTrackerServicerApi'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingBottom: '0',
  },
  content: {
    maxHeight: '80vh',
    overflow: 'auto',
    padding: '15px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 0,
  },
}

interface Props {
  data: IFlightTrackerServicer | undefined | null
  onClose: () => void
  refetch: () => void
  open: boolean
}

const validationSchema = yup.object({
  servicerName: yup.string().required('Servicer name is required'),
})

export const ServicerPortalForm = ({ onClose, refetch, open, data }: Props) => {
  const [createServicer, { isLoading: cIsLoading }] = useCreateFlightTrackerServicerMutation()
  const [updateServicer, { isLoading: uIsLoading }] = useUpdateFlightTrackerServicerMutation()
  const user = useAppSelector((state) => state.userState.user)

  const formik = useFormik({
    initialValues: {
      servicerName: data?.flightTrackerStatusServicer || '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (data?.flightTrackerStatusServicerId) {
        const requestData = {
          flightTrackerStatusServicerId: data.flightTrackerStatusServicerId,
          flightTrackerStatusServicer: values.servicerName,
          updatedBy: `${user?.firstName} ${user?.lastName}`,
        }
        updateServicer(requestData)
          .then(() => {
            toast.success('Servicer updated successfully')
            refetch()
            onClose()
          })
          .catch((error: any) => {
            toast.error('Failed to update servicer')
            console.error(error)
          })
      } else {
        const requestData = {
          flightTrackerStatusServicer: values.servicerName,
          Application: 'Intranet',
          createdBy: `${user?.firstName} ${user?.lastName}`,
        }
        createServicer(requestData)
          .then(() => {
            toast.success('Servicer created successfully')
            refetch()
            onClose()
          })
          .catch((error: any) => {
            toast.error('Failed to create servicer')
            console.error(error)
          })
      }
    },
  })

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
  }, [open])

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            {data?.flightTrackerStatusServicerId ? 'Edit Servicer' : 'Create Servicer'}
          </Typography>
        </Box>
        <Box sx={styles.content}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id='servicerName'
              name='servicerName'
              label='Servicer Name'
              autoComplete='off'
              value={formik.values.servicerName}
              onChange={formik.handleChange}
              error={formik.touched.servicerName && Boolean(formik.errors.servicerName)}
              helperText={formik.touched.servicerName && formik.errors.servicerName}
            />
            <Box sx={{ textAlign: 'right', marginTop: '20px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                size='large'
                type='submit'
                disabled={cIsLoading || uIsLoading}
                sx={{ borderRadius: '20px' }}
              >
                Submit
                {(cIsLoading || uIsLoading) && <CircularProgress size={20} sx={{ ml: 1 }} />}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
