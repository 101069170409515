import React, { Fragment, useState, useMemo } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Chip,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import EditIcon from '@mui/icons-material/Edit'
import { IFlightTrackerConsumerStatus } from '../../types'
import { useUpdateFlightTrackerConsumerStatusStatusMutation } from '../../redux/api/flightTrackerConsumerStatusApi'
import moment from 'moment'

const styles = {
  container: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  actionButton: {
    backgroundColor: '#1976d2',
    color: 'white',
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#115293',
    },
  },
  modalButton: {
    textTransform: 'none',
    textDecoration: 'none',
    backgroundColor: '#f1f1f1',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
}

// Helper functions
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof IFlightTrackerConsumerStatus>(order: Order, orderBy: Key) {
  return order === 'desc'
    ? (a: IFlightTrackerConsumerStatus, b: IFlightTrackerConsumerStatus) =>
        descendingComparator(a, b, orderBy)
    : (a: IFlightTrackerConsumerStatus, b: IFlightTrackerConsumerStatus) =>
        -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

// Head cells
interface HeadCell {
  id: keyof IFlightTrackerConsumerStatus
  label: string
}

const headCells: readonly HeadCell[] = [
  { id: 'flightTrackerStatusConsumerId', label: 'ID' },
  { id: 'isActiveFlg', label: 'Active Status' },
  { id: 'flightTrackerStatusConsumer', label: 'Consumer Portal Status' },
  { id: 'sortOrder', label: 'Sort Order' },
  { id: 'createdOn', label: 'Created' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'updatedOn', label: 'Updated' },
  { id: 'updatedBy', label: 'Updated By' },
]

interface ConsumerStatusTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IFlightTrackerConsumerStatus,
  ) => void
  order: Order
  orderBy: string
  numSelected: number
  rowCount: number
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function ConsumerStatusTableHead(props: ConsumerStatusTableHeadProps) {
  const { order, orderBy, onRequestSort, numSelected, rowCount, onSelectAll } = props
  const createSortHandler =
    (property: keyof IFlightTrackerConsumerStatus) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#1976d2' }}>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAll}
            inputProps={{ 'aria-label': 'select all displayed rows' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: 'white' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align='left' padding='checkbox' sx={{ color: 'white' }}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

interface Props {
  isLoading: boolean
  rows: IFlightTrackerConsumerStatus[]
  searchKey: string
  editConsumerStatus: (data: IFlightTrackerConsumerStatus) => void
  refetch: () => void
}

export const ConsumerPortalStatusTable = ({
  isLoading,
  rows,
  searchKey,
  editConsumerStatus,
  refetch,
}: Props) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof IFlightTrackerConsumerStatus>(
    'flightTrackerStatusConsumerId',
  )
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selected, setSelected] = useState<number[]>([])
  const [open, setOpen] = useState(false)
  const [action, setAction] = useState<string>('')

  const [
    updateFlightTrackerConsumerStatusStatus,
    {
      isLoading: statusIsLoading,
      // isError: statusIsError,
      // error: statusError,
      // isSuccess: statusIsSuccess,
    },
  ] = useUpdateFlightTrackerConsumerStatusStatusMutation()

  const actionIsLoading = useMemo(() => statusIsLoading, [statusIsLoading])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IFlightTrackerConsumerStatus,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSelect = (id: number) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id],
    )
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(displayedRows.map((row) => Number(row.flightTrackerStatusConsumerId)))
    } else {
      setSelected([])
    }
  }

  const handleAction = (actionType: string) => {
    setAction(actionType)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setAction('')
  }

  const handleConfirm = async () => {
    switch (action) {
      case 'activate':
        await updateFlightTrackerConsumerStatusStatus({
          consumerIdList: selected,
          isActiveFlg: 1,
          application: 'Intranet',
        })
        break
      case 'deactivate':
        await updateFlightTrackerConsumerStatusStatus({
          consumerIdList: selected,
          isActiveFlg: 0,
          application: 'Intranet',
        })
        break
      default:
        break
    }
    setSelected([])
    handleClose()
    refetch()
  }

  const filteredRows = rows.filter((row) =>
    Object.values(row).some(
      (value) => value && value.toString().toLowerCase().includes(searchKey.toLowerCase()),
    ),
  )

  const sortedRows = stableSort(filteredRows, getComparator(order, orderBy))
  const displayedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows.length) : 0

  if (isLoading) {
    return (
      <Box sx={styles.container}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Fragment>
      <Box sx={styles.actionsContainer}>
        <Button
          variant='contained'
          sx={styles.actionButton}
          disabled={!selected.length || actionIsLoading}
          onClick={() => handleAction('activate')}
        >
          Activate
        </Button>
        <Button
          variant='contained'
          sx={styles.actionButton}
          disabled={!selected.length || actionIsLoading}
          onClick={() => handleAction('deactivate')}
        >
          Deactivate
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to {action} the selected statuses?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={styles.modalButton}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} sx={styles.modalButton}>
            Confirm
            {actionIsLoading && <CircularProgress size={20} sx={{ ml: 1 }} />}
          </Button>
        </DialogActions>
      </Dialog>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='medium'>
            <ConsumerStatusTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              numSelected={selected.length}
              rowCount={displayedRows.length}
              onSelectAll={handleSelectAll}
            />
            <TableBody>
              {displayedRows.map((row) => {
                const isItemSelected = selected.includes(row.flightTrackerStatusConsumerId)
                return (
                  <TableRow
                    hover
                    onClick={() => handleSelect(row.flightTrackerStatusConsumerId)}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.flightTrackerStatusConsumerId}
                    selected={isItemSelected}
                  >
                    <TableCell padding='checkbox'>
                      <Checkbox checked={isItemSelected} />
                    </TableCell>
                    <TableCell>{row.flightTrackerStatusConsumerId}</TableCell>
                    <TableCell>
                      {row?.isActiveFlg ? (
                        <Chip label='Active' color='primary' size='small' />
                      ) : (
                        <Chip label='Inactive' color='error' size='small' />
                      )}
                    </TableCell>
                    <TableCell>{row.flightTrackerStatusConsumer}</TableCell>
                    <TableCell>{row.sortOrder}</TableCell>
                    <TableCell>{moment(row?.createdOn).format('L')}</TableCell>
                    <TableCell>{row.createdBy}</TableCell>
                    <TableCell>{moment(row?.updatedOn).format('L')}</TableCell>
                    <TableCell>{row.updatedBy}</TableCell>
                    <TableCell padding='checkbox'>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation()
                          editConsumerStatus(row)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 25]}
          component='div'
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  )
}
