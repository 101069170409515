import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  ActiveFlg,
  DispatchStatusAllOtherPortals,
  GenericResponse,
  IDispatchStatus,
  IDispatchStatusCreateRequest,
  IDispatchStatusPortalDetails,
  IDispatchStatusUpdateRequest,
  IDispatchStatusUpdateStatusRequest,
} from '../../types'

// Define the API service
export const dispatchStatusApi = createApi({
  reducerPath: 'dispatchStatusApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['IDispatchStatus'],
  endpoints: (builder) => ({
    getDispatchStatusList: builder.query<IDispatchStatus[], null | ActiveFlg>({
      query(status: null | ActiveFlg) {
        return {
          url: 'statusdispatch/get',
          method: 'GET',
          credentials: 'include',
          params: {
            isActiveFlg: status,
          },
        }
      },
      transformResponse: (result: { data: { statusDispatchList: IDispatchStatus[] } }) =>
        result.data.statusDispatchList,
    }),
    getDispatchStatusAllPortalStatus: builder.query<DispatchStatusAllOtherPortals, void>({
      query() {
        return {
          url: `statusdispatch/other/status`,
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: DispatchStatusAllOtherPortals }) => result.data,
    }),
    createDispatchStatus: builder.mutation<GenericResponse, IDispatchStatusCreateRequest>({
      query(data) {
        return {
          url: 'statusdispatch/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateDispatchStatus: builder.mutation<GenericResponse, IDispatchStatusUpdateRequest>({
      query(data) {
        return {
          url: 'statusdispatch/update',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
    updateDispatchStatusActiveStatus: builder.mutation<
      GenericResponse,
      IDispatchStatusUpdateStatusRequest
    >({
      query(data) {
        return {
          url: 'statusdispatch/statusupdate',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useGetDispatchStatusListQuery,
  useGetDispatchStatusAllPortalStatusQuery,
  useCreateDispatchStatusMutation,
  useUpdateDispatchStatusMutation,
  useUpdateDispatchStatusActiveStatusMutation,
} = dispatchStatusApi
