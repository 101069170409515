import React, { useEffect } from 'react'
import {
  Button,
  TextField,
  Stack,
  Typography,
  Box,
  Modal,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { useAppSelector } from '../../redux/store'
import {
  IDispatchStatus,
  IDispatchStatusConsumer,
  IDispatchStatusDealer,
  IDispatchStatusServicer,
} from '../../types/dispatchStatus'
import {
  useCreateDispatchStatusMutation,
  useGetDispatchStatusAllPortalStatusQuery,
  useUpdateDispatchStatusMutation,
} from '../../redux/api/dispatchStatusApi'

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
    paddingBottom: '0',
  },
  content: {
    maxHeight: '80vh',
    overflow: 'auto',
    padding: '15px',
  },
  headerStyle: {
    borderRadius: '12px',
    display: 'flex',
    p: 2,
    justifyContent: 'center',
    backgroundColor: '#1976d2',
    mb: 0,
  },
}

interface Props {
  data: IDispatchStatus | undefined | null
  onClose: () => void
  refetch: () => void
  open: boolean
}

const validationSchema = yup.object({
  statusName: yup.string().required('Status name is required'),
  sortOrder: yup.number().typeError('Sort order must be a number'),
  flightTrackerStatusConsumerId: yup
    .number()
    .typeError('Please select a Consumer Status')
    .required('Consumer status is required'),
  flightTrackerStatusServicerId: yup
    .number()
    .typeError('Please select a Servicer Status')
    .required('Servicer status is required'),
  flightTrackerStatusDealerId: yup
    .number()
    .typeError('Please select a Dealer Status')
    .required('Dealer status is required'),
})

export const DispatchStatusForm = ({ onClose, refetch, open, data }: Props) => {
  const {
    data: allDispatchStatusList,
    isLoading: loadingDispatchStatusList,
    refetch: refetchDispatchStatus,
  } = useGetDispatchStatusAllPortalStatusQuery()
  const [createDispatchStatus, { isLoading: cIsLoading }] = useCreateDispatchStatusMutation()
  const [updateDispatchStatus, { isLoading: uIsLoading }] = useUpdateDispatchStatusMutation()
  const user = useAppSelector((state) => state.userState.user)

  const formik = useFormik({
    initialValues: {
      statusName: data?.flightTrackerStatusDispatch || '',
      sortOrder: data?.sortOrder || 10,
      flightTrackerStatusConsumerId:
        (data?.isActiveFlg && data?.flightTrackerStatusConsumerId) || null,
      flightTrackerStatusServicerId:
        (data?.isActiveFlg && data?.flightTrackerStatusServicerId) || null,
      flightTrackerStatusDealerId: (data?.isActiveFlg && data?.flightTrackerStatusDealerId) || null,
    },
    validationSchema,
    onSubmit: (values) => {
      if (data?.flightTrackerStatusDispatchId) {
        const requestData = {
          flightTrackerStatusDispatchId: data.flightTrackerStatusDispatchId,
          flightTrackerStatusDispatch: values.statusName,
          flightTrackerStatusConsumerId: values.flightTrackerStatusConsumerId!,
          flightTrackerStatusServicerId: values.flightTrackerStatusServicerId!,
          flightTrackerStatusDealerId: values.flightTrackerStatusDealerId!,
          sortOrder: values.sortOrder ? values.sortOrder : 10,
          application: 'intranet',
          updatedBy: `${user?.firstName} ${user?.lastName}`,
        }
        updateDispatchStatus(requestData)
          .then(() => {
            toast.success('Dispatch status updated successfully')
            refetch()
            onClose()
          })
          .catch((error: any) => {
            toast.error('Failed to update dispatch status')
            console.error(error)
          })
      } else {
        const requestData = {
          flightTrackerStatusDispatch: values.statusName,
          flightTrackerStatusConsumerId: values.flightTrackerStatusConsumerId!,
          flightTrackerStatusServicerId: values.flightTrackerStatusServicerId!,
          flightTrackerStatusDealerId: values.flightTrackerStatusDealerId!,
          sortOrder: values.sortOrder ? values.sortOrder : 10,
          application: 'intranet',
          createdBy: `${user?.firstName} ${user?.lastName}`,
        }
        createDispatchStatus(requestData)
          .then(() => {
            toast.success('Dispatch status created successfully')
            refetch()
            onClose()
          })
          .catch((error: any) => {
            toast.error('Failed to create dispatch status')
            console.error(error)
          })
      }
    },
  })

  useEffect(() => {
    if (open) {
      formik.resetForm()
    }
  }, [open])

  const reset = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={styles.container}>
        <Box sx={styles.headerStyle}>
          <Typography id='modal-modal-title' variant='h5' component='h2' color='white'>
            {data?.flightTrackerStatusDispatchId
              ? 'Edit Dispatch Status'
              : 'Create Dispatch Status'}
          </Typography>
        </Box>
        <Box sx={styles.content}>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              id='statusName'
              name='statusName'
              label='Status Name'
              autoComplete='off'
              value={formik.values.statusName}
              onChange={formik.handleChange}
              error={formik.touched.statusName && Boolean(formik.errors.statusName)}
              helperText={formik.touched.statusName && formik.errors.statusName}
            />
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              id='sortOrder'
              name='sortOrder'
              label='Sort Order'
              autoComplete='off'
              value={formik.values.sortOrder}
              onChange={formik.handleChange}
              error={formik.touched.sortOrder && Boolean(formik.errors.sortOrder)}
              helperText={formik.touched.sortOrder && formik.errors.sortOrder}
            />
            <Box sx={{ opacity: loadingDispatchStatusList ? '50%' : '100%' }}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id='consumer-status-label'>Consumer Flight Tracker Status</InputLabel>
                <Select
                  labelId='consumer-status-label'
                  id='flightTrackerStatusConsumerId'
                  name='flightTrackerStatusConsumerId'
                  value={formik.values.flightTrackerStatusConsumerId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.flightTrackerStatusConsumerId &&
                    Boolean(formik.errors.flightTrackerStatusConsumerId)
                  }
                  label='Consumer Flight Tracker Status'
                >
                  {allDispatchStatusList?.dispatchStatusConsumer.map(
                    (consumer: IDispatchStatusConsumer) => (
                      <MenuItem
                        key={consumer.flightTrackerStatusConsumerId}
                        value={consumer.flightTrackerStatusConsumerId}
                      >
                        {consumer.flightTrackerStatusConsumer}
                      </MenuItem>
                    ),
                  )}
                </Select>
                {formik.touched.flightTrackerStatusConsumerId &&
                  formik.errors.flightTrackerStatusConsumerId && (
                    <Typography color='error'>
                      {formik.errors.flightTrackerStatusConsumerId}
                    </Typography>
                  )}
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id='dealer-status-label'>Dealer Flight Tracker Status</InputLabel>
                <Select
                  labelId='dealer-status-label'
                  id='flightTrackerStatusDealerId'
                  name='flightTrackerStatusDealerId'
                  value={formik.values.flightTrackerStatusDealerId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.flightTrackerStatusDealerId &&
                    Boolean(formik.errors.flightTrackerStatusDealerId)
                  }
                  label='Dealer Flight Tracker Status'
                >
                  {allDispatchStatusList?.dispatchStatusDealer.map(
                    (dealer: IDispatchStatusDealer) => (
                      <MenuItem
                        key={dealer.flightTrackerStatusDealerId}
                        value={dealer.flightTrackerStatusDealerId}
                      >
                        {dealer.flightTrackerStatusDealer}
                      </MenuItem>
                    ),
                  )}
                </Select>
                {formik.touched.flightTrackerStatusDealerId &&
                  formik.errors.flightTrackerStatusDealerId && (
                    <Typography color='error'>
                      {formik.errors.flightTrackerStatusDealerId}
                    </Typography>
                  )}
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id='servicer-status-label'>Servicer Flight Tracker Status</InputLabel>
                <Select
                  labelId='servicer-status-label'
                  id='flightTrackerStatusServicerId'
                  name='flightTrackerStatusServicerId'
                  value={formik.values.flightTrackerStatusServicerId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.flightTrackerStatusServicerId &&
                    Boolean(formik.errors.flightTrackerStatusServicerId)
                  }
                  label='Servicer Flight Tracker Status'
                >
                  {allDispatchStatusList?.dispatchStatusServicer.map(
                    (servicer: IDispatchStatusServicer) => (
                      <MenuItem
                        key={servicer.flightTrackerStatusServicerId}
                        value={servicer.flightTrackerStatusServicerId}
                      >
                        {servicer.flightTrackerStatusServicer}
                      </MenuItem>
                    ),
                  )}
                </Select>
                {formik.touched.flightTrackerStatusServicerId &&
                  formik.errors.flightTrackerStatusServicerId && (
                    <Typography color='error'>
                      {formik.errors.flightTrackerStatusServicerId}
                    </Typography>
                  )}
              </FormControl>
            </Box>
            <Box sx={{ textAlign: 'right', marginTop: '20px' }}>
              <Button
                variant='outlined'
                size='large'
                sx={{ mr: 2, borderRadius: '20px' }}
                onClick={reset}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                size='large'
                type='submit'
                disabled={cIsLoading || uIsLoading || !formik.isValid || loadingDispatchStatusList}
                sx={{ borderRadius: '20px' }}
              >
                {loadingDispatchStatusList
                  ? 'Loading'
                  : data?.flightTrackerStatusDispatchId && !data?.isActiveFlg
                  ? 'Activate'
                  : 'Submit'}
                {(cIsLoading || uIsLoading || loadingDispatchStatusList) && (
                  <CircularProgress size={20} sx={{ ml: 1 }} />
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Modal>
  )
}
