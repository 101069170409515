import React, { useState, useEffect } from 'react'
import {
  Box,
  TextField,
  CircularProgress,
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
} from '@mui/material'
import { ServicerPortalForm } from './ServicerPortalForm'
import { ServicerPortalTable } from '../../components/Table/ServicerPortalTable'
import SearchIcon from '@mui/icons-material/Search'
import AddIcon from '@mui/icons-material/Add'
import { IFlightTrackerServicer } from '../../types/servicerPortal'
import { useGetFlightTrackerServicersQuery } from '../../redux/api/flightTrackerServicerApi'

const styles = {
  container: {
    padding: '20px',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  searchInput: {
    width: '100%',
    marginRight: '10px',
  },
  addButton: {
    textTransform: 'none',
    textDecoration: 'none',
  },
}

export const ServicerPortalPage = () => {
  const [searchKey, setSearchKey] = useState('')
  const [editingServicer, setEditingServicer] = useState<IFlightTrackerServicer | null>(null)
  const { data, isLoading, refetch } = useGetFlightTrackerServicersQuery(null)

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(event.target.value)
  }

  const handleEditServicer = (servicer: IFlightTrackerServicer) => {
    setEditingServicer(servicer)
  }

  const handleCloseForm = () => {
    setEditingServicer(null)
    refetch()
  }

  const handleAddServicer = () => {
    setEditingServicer({} as IFlightTrackerServicer)
  }

  return (
    <Box sx={styles.container}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Flight Tracker Servicer Statuses
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={handleSearchChange}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px', textDecoration: 'none' }}
            startIcon={<AddIcon />}
            onClick={handleAddServicer}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      {isLoading ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ServicerPortalTable
          isLoading={isLoading}
          rows={data || []}
          searchKey={searchKey}
          editServicer={handleEditServicer}
          refetch={refetch}
        />
      )}
      {editingServicer && (
        <ServicerPortalForm
          data={editingServicer}
          onClose={handleCloseForm}
          refetch={refetch}
          open={!!editingServicer}
        />
      )}
    </Box>
  )
}
