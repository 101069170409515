import { createApi } from '@reduxjs/toolkit/query/react'
import customElxFetchBase, { customModuleBaseQuery } from './customElxFetchBase'
import { IElxData } from '../../types'

export const moduleApi = createApi({
  reducerPath: 'moduleApi',
  baseQuery: customModuleBaseQuery,
  tagTypes: ['Module'],
  endpoints: (builder) => ({
    getModuleList: builder.query({
      query() {
        return {
          url: 'module/all',
          method: 'GET',
          credentials: 'include',
        }
      },
      transformResponse: (result: { data: { modules: IElxData[] } }) => result.data.modules,
    }),

    createModule: builder.mutation({
      query(data) {
        return {
          url: 'module/create',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),

    updateModuleDetails: builder.mutation({
      query(data) {
        return {
          url: 'module/update',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),

    updateModuleStatus: builder.mutation({
      query(data) {
        return {
          url: 'module/update-status',
          method: 'POST',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetModuleListQuery,
  useCreateModuleMutation,
  useUpdateModuleDetailsMutation,
  useUpdateModuleStatusMutation,
} = moduleApi
