import { createApi } from '@reduxjs/toolkit/query/react'
import customOpwFetchBase from './customOpwFetchBase'
import {
  IFlightTrackerDealer,
  ICreateFlightTrackerDealerRequest,
  IUpdateFlightTrackerDealerRequest,
  IUpdateFlightTrackerDealerStatusRequest,
  ActiveFlg,
} from '../../types'

// Assuming GenericResponse is a type representing a generic API response
interface GenericResponse {
  status: string
  message: string
}

// Slice action placeholder
const setFlightTrackerDealers = (data: IFlightTrackerDealer[]) => ({
  type: 'setFlightTrackerDealers',
  payload: data,
})

export const flightTrackerDealerApi = createApi({
  reducerPath: 'flightTrackerDealerApi',
  baseQuery: customOpwFetchBase,
  tagTypes: ['IFlightTrackerDealer'],
  endpoints: (builder) => ({
    getFlightTrackerDealers: builder.query<IFlightTrackerDealer[], ActiveFlg | null>({
      query(isActiveFlg) {
        return {
          url: 'dealerflighttracker/get',
          method: 'GET',
          credentials: 'include',
          params: {
            isActiveFlg: isActiveFlg,
          },
        }
      },
      transformResponse: (result: { data: { request: IFlightTrackerDealer[] } }) => {
        return result.data.request
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setFlightTrackerDealers(data))
        } catch (error) {
          console.log(error)
        }
      },
    }),
    createFlightTrackerDealer: builder.mutation<GenericResponse, ICreateFlightTrackerDealerRequest>(
      {
        query(data) {
          return {
            url: 'dealerflighttracker/create',
            method: 'POST',
            body: data,
            credentials: 'include',
          }
        },
      },
    ),
    updateFlightTrackerDealer: builder.mutation<GenericResponse, IUpdateFlightTrackerDealerRequest>(
      {
        query(data) {
          return {
            url: 'dealerflighttracker/update',
            method: 'PUT',
            body: data,
            credentials: 'include',
          }
        },
      },
    ),
    updateFlightTrackerDealerStatus: builder.mutation<
      GenericResponse,
      IUpdateFlightTrackerDealerStatusRequest
    >({
      query(data) {
        return {
          url: 'dealerflighttracker/updatestatus',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
    deleteFlightTrackerDealers: builder.mutation<
      GenericResponse,
      IUpdateFlightTrackerDealerStatusRequest
    >({
      query(data) {
        return {
          url: 'dealerflighttracker/updatestatus',
          method: 'PUT',
          body: data,
          credentials: 'include',
        }
      },
    }),
  }),
})

export const {
  useGetFlightTrackerDealersQuery,
  useCreateFlightTrackerDealerMutation,
  useUpdateFlightTrackerDealerMutation,
  useUpdateFlightTrackerDealerStatusMutation,
  useDeleteFlightTrackerDealersMutation,
} = flightTrackerDealerApi
