import React, { useState, useMemo } from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Chip,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import moment from 'moment'
import EditIcon from '@mui/icons-material/Edit'
import { IFlightTrackerServicer } from '../../types/servicerPortal'
import {
  useDeleteFlightTrackerServicerStatusMutation,
  useUpdateFlightTrackerServicerStatusMutation,
} from '../../redux/api/flightTrackerServicerApi'

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '20px',
    marginBottom: '20px',
  },
  actionButton: {
    backgroundColor: '#1976d2',
    color: 'white',
    textTransform: 'none',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: '#115293',
    },
  },
  modalButton: {
    textTransform: 'none',
    textDecoration: 'none',
    backgroundColor: '#f1f1f1',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
}

// Helper functions
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1
  return 0
}

type Order = 'asc' | 'desc'

function getComparator<Key extends keyof IFlightTrackerServicer>(order: Order, orderBy: Key) {
  return order === 'desc'
    ? (a: IFlightTrackerServicer, b: IFlightTrackerServicer) => descendingComparator(a, b, orderBy)
    : (a: IFlightTrackerServicer, b: IFlightTrackerServicer) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

// Head cells
interface HeadCell {
  id: keyof IFlightTrackerServicer
  label: string
}

const headCells: readonly HeadCell[] = [
  { id: 'flightTrackerStatusServicerId', label: 'ID' },
  { id: 'isActiveFlg', label: 'Active Status' },
  { id: 'flightTrackerStatusServicer', label: 'Servicer Name' },
  { id: 'createdOn', label: 'Created' },
  { id: 'createdBy', label: 'Created By' },
  { id: 'updatedOn', label: 'Updated' },
  { id: 'updatedBy', label: 'Updated By' },
]

interface ServicerTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IFlightTrackerServicer) => void
  order: Order
  orderBy: string
  numSelected: number
  rowCount: number
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function ServicerTableHead(props: ServicerTableHeadProps) {
  const { onRequestSort, order, orderBy, numSelected, rowCount, onSelectAll } = props
  const createSortHandler =
    (property: keyof IFlightTrackerServicer) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#1976d2' }}>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAll}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: 'white' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
}

interface Props {
  rows: IFlightTrackerServicer[]
  isLoading: boolean
  searchKey: string
  editServicer: (servicer: IFlightTrackerServicer) => void
  refetch: () => void
}

export const ServicerPortalTable = ({
  rows,
  isLoading,
  searchKey,
  editServicer,
  refetch,
}: Props) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof IFlightTrackerServicer>(
    'flightTrackerStatusServicer',
  )
  const [selected, setSelected] = useState<number[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [showDialog, setShowDialog] = useState(false)
  const [dialogAction, setDialogAction] = useState<'activate' | 'deactivate' | 'delete' | null>(
    null,
  )

  const [updateServicerStatus, { isLoading: statusIsLoading }] =
    useUpdateFlightTrackerServicerStatusMutation()
  const [deleteServicers, { isLoading: deleteIsLoading }] =
    useDeleteFlightTrackerServicerStatusMutation()

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IFlightTrackerServicer,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.flightTrackerStatusServicerId)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, servicerId: number) => {
    const selectedIndex = selected.indexOf(servicerId)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, servicerId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenDialog = (action: 'activate' | 'deactivate' | 'delete') => {
    setDialogAction(action)
    setShowDialog(true)
  }

  const handleCloseDialog = () => {
    setDialogAction(null)
    setShowDialog(false)
  }

  const handleDialogAction = () => {
    if (dialogAction === 'activate') {
      updateServicerStatus({ servicerIds: selected, isActiveFlg: 1 }).then(() => {
        refetch()
        setSelected([])
        handleCloseDialog()
      })
    } else if (dialogAction === 'deactivate') {
      updateServicerStatus({ servicerIds: selected, isActiveFlg: 0 }).then(() => {
        refetch()
        setSelected([])
        handleCloseDialog()
      })
    } else if (dialogAction === 'delete') {
      deleteServicers({ servicerIds: selected, isActiveFlg: 0 }).then(() => {
        refetch()
        setSelected([])
        handleCloseDialog()
      })
    }
  }

  const filteredRows = useMemo(
    () =>
      rows.filter((row) =>
        row?.flightTrackerStatusServicer?.toLowerCase().includes(searchKey.toLowerCase()),
      ),
    [rows, searchKey],
  )

  const sortedRows = useMemo(
    () => stableSort(filteredRows, getComparator(order, orderBy)),
    [filteredRows, order, orderBy],
  )

  const isSelected = (servicerId: number) => selected.indexOf(servicerId) !== -1

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, sortedRows.length - page * rowsPerPage)

  return (
    <>
      <Box sx={styles.actionsContainer}>
        <Button
          variant='contained'
          sx={styles.actionButton}
          disabled={selected.length === 0 || statusIsLoading || deleteIsLoading}
          onClick={() => handleOpenDialog('activate')}
        >
          Activate
        </Button>
        <Button
          variant='contained'
          sx={styles.actionButton}
          disabled={selected.length === 0 || statusIsLoading || deleteIsLoading}
          onClick={() => handleOpenDialog('deactivate')}
        >
          Deactivate
        </Button>
        <Button
          variant='contained'
          sx={styles.actionButton}
          disabled={selected.length === 0 || statusIsLoading || deleteIsLoading}
          onClick={() => handleOpenDialog('delete')}
        >
          Delete
        </Button>
      </Box>
      <Box>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table aria-labelledby='servicerPortalTable' sx={{ minWidth: 750 }}>
              <ServicerTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                onSelectAll={handleSelectAll}
              />
              <TableBody>
                {sortedRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.flightTrackerStatusServicerId)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.flightTrackerStatusServicerId)}
                        role='checkbox'
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.flightTrackerStatusServicerId}
                        selected={isItemSelected}
                      >
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell component='th' id={labelId} scope='row'>
                          {row.flightTrackerStatusServicerId}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={row.isActiveFlg ? 'Active' : 'Inactive'}
                            color={row.isActiveFlg ? 'primary' : 'error'}
                          />
                        </TableCell>
                        <TableCell>{row.flightTrackerStatusServicer}</TableCell>
                        <TableCell>{moment(row.createdOn).format('MM/DD/YYYY')}</TableCell>
                        <TableCell>{row.createdBy}</TableCell>
                        <TableCell>{moment(row.updatedOn).format('MM/DD/YYYY')}</TableCell>
                        <TableCell>{row.updatedBy}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation()
                              editServicer(row)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
              </TableBody>
            </Table>
            {isLoading && (
              <Box sx={styles.container}>
                <CircularProgress />
              </Box>
            )}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={sortedRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Dialog
        open={showDialog}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to {dialogAction} the selected servicer(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} sx={styles.modalButton}>
            Cancel
          </Button>
          <Button onClick={handleDialogAction} sx={styles.modalButton} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
