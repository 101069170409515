import React from 'react'
import { toast } from 'react-toastify'
import { Stack } from '@mui/system'
import { Button, Container, Typography, OutlinedInput, InputAdornment } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import { FullScreenLoader, ElxDataTable } from '../../components'

import { IElxData, IUpdateElxDataStatusRequest } from '../../types'
import {
  useGetLeadTypeListQuery,
  useUpdateLeadTypeStatusMutation,
} from '../../redux/api/leadTypeApi'
import { ModuleTypeForm } from './ModuleForm'
import { useGetApplicationListQuery } from '../../redux/api/applicationApi'
import { capitalize } from 'lodash'
import { useGetActiveUserRoleListQuery } from '../../redux/api/roleApi'
import { useGetModuleListQuery, useUpdateModuleStatusMutation } from '../../redux/api/moduleApi'

export function ModulePage() {
  const { isLoading, data, refetch } = useGetModuleListQuery(null)
  const { data: applicationList } = useGetApplicationListQuery(1)
  const { data: userRoleList } = useGetActiveUserRoleListQuery()

  const [updateStatus, { isSuccess, isError, isLoading: loading, error }] =
    useUpdateModuleStatusMutation()
  const [searchKey, setSearchKey] = React.useState<string>('')

  React.useEffect(() => {
    if (isSuccess) {
      toast.success('Success!')
      refetch()
    }
    if (isError) {
      if (Array.isArray((error as any)?.data?.error)) {
        const errors = error as any
        errors?.data.error.forEach((el: any) =>
          toast.error(el?.message ?? 'Something Failed!', {
            position: 'top-right',
          }),
        )
      } else {
        toast.error((error as any)?.data?.message ?? 'Something Failed!', {
          position: 'top-right',
        })
      }
    }
  }, [loading])
  const [isVisible, setIsVisible] = React.useState(false)
  const [editableLeadType, setEditableLeadType] = React.useState<IElxData | undefined>()

  if (isLoading || !data || loading) {
    return <FullScreenLoader />
  }

  const handleEditable = (data: IElxData) => {
    setEditableLeadType(data)
    setIsVisible(true)
  }

  const handleStatusUpdate = (data: IUpdateElxDataStatusRequest) => {
    const updatedData = {
      moduleIds: Array.isArray(data.moduleIds)
        ? data.moduleIds.map(String)
        : [String(data.moduleIds)],
      isActiveFlg: data.isActiveFlg === 1,
    }
    updateStatus(updatedData)
  }

  return (
    <Container maxWidth='xl' sx={{ paddingTop: 4, paddingBottom: 8 }}>
      <Stack direction='row' justifyContent='space-between' mb={2} mr={1}>
        <Typography variant='h4' color='#1E90FF'>
          Module Page
        </Typography>
        <Stack direction='row' alignItems='center'>
          <OutlinedInput
            placeholder='Search'
            onChange={(event) => setSearchKey(event.target.value)}
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            }
            sx={{ marginRight: '20px' }}
          />
          <Button
            variant='outlined'
            sx={{ borderRadius: '20px', height: '40px', textDecoration: 'none' }}
            startIcon={<AddIcon />}
            onClick={() => {
              setIsVisible(true)
              setEditableLeadType(undefined)
            }}
            size='medium'
          >
            Create
          </Button>
        </Stack>
      </Stack>
      <ElxDataTable
        editable={true}
        checkbox={true}
        headLabelForId={'Module'}
        rows={data ?? []}
        searchKey={searchKey}
        editElxData={(data) => handleEditable(data)}
        disableElxData={handleStatusUpdate}
        perPage={5}
        gapPerPage={5}
        refetch={refetch}
      />
      <ModuleTypeForm
        open={isVisible}
        onClose={() => setIsVisible(false)}
        leadType={editableLeadType}
        refetch={refetch}
        applications={applicationList?.map((application) => ({
          label: capitalize(application.application),
          value: application.applicationId,
        }))}
        options={userRoleList?.map((userRole) => ({
          label: capitalize(userRole.userRole),
          value: userRole.userRoleId,
        }))}
      />
    </Container>
  )
}
